import { Link } from "gatsby";
import React from "react";

function Pricing({ data }) {
  return (
    <div className="max-w-7xl mx-auto p-4 grid grid-cols-1 md:grid-cols-2 pt-12 md:pt-24 md:pb-12 lg:flex justify-between items-center gap-16">
      <div className="w-full lg:w-5/12">
        <p className="text-orange text-xl mb-6 font-opensans">
          {data.sub_heading}
        </p>
        <h2 className="text-4xl font-bold font-secondary">{data.heading}</h2>
        <div
          className="text-lg py-6 font-opensans"
          dangerouslySetInnerHTML={{ __html: data.description }}
        ></div>
        <div className="md:flex  md:gap-4 md:first-line:items-center">
          {data?.cta1?.link && (
            <Link
              to={data.cta1.link}
              className="bg-orange text-center md:text-left block px-6 py-2 rounded-md text-white font-opensans"
            >
              {data.cta1.text}
            </Link>
          )}
          {data?.cta2?.link && (
            <Link
              to={data.cta2.link}
              className="text-orange text-center md:text-left block px-6 py-2 rounded-md bg-white text-secondary"
            >
              {data.cta2.text}
            </Link>
          )}
        </div>
      </div>
      <div className="w-full lg:w-7/12 lg:flex gap-8 hidden">
        {data?.cards?.map((card) => (
          <div class="shadow-lg rounded-md w-full overflow-hidden mt-8 sm:mt-0">
            <h3
              className={`bg-[${card?.background_color}] ${
                card?.text_color ? card?.text_color : "text-white"
              } p-4 text-center text-xl`}
            >
              {card.title}
            </h3>
            <div class="p-4">
              <p class="text-center mb-6 text-xl">
                From
                <br />
                <span class="text-5xl font-bold font-secondary inline-block mt-2">
                  {card.price}
                </span>{" "}
                / {card.unit_price}
                <br />
                <span class="text-sm opacity-50 text-gray-500 font-thin">
                  *price may vary
                </span>
              </p>
              <div
                class="flex justify-center language-feature text-lg"
                style="margin-bottom: 1rem;"
                dangerouslySetInnerHTML={{ __html: card.features }}
              ></div>
            </div>
          </div>
        ))}
      </div>

      {data?.cards?.map((card) => (
        <div class="shadow-lg rounded-md w-full overflow-hidden mt-8 sm:mt-0 lg:hidden">
          <h3
            className={`
              bg-[${card?.background_color}]
              ${
                card?.text_color ? card?.text_color : "text-white"
              } p-4 text-center text-xl`}
          >
            {card.title}
          </h3>
          <div class="p-4">
            <p class="text-center mb-6 text-xl">
              From
              <br />
              <span class="text-5xl font-bold font-secondary inline-block mt-2">
                {card.price}
              </span>{" "}
              / {card.unit_price}
              <br />
              <span class="text-sm opacity-50 text-gray-500 font-thin">
                *price may vary
              </span>
            </p>
            <div
              class="flex justify-center language-feature text-lg"
              style="margin-bottom: 1rem;"
              dangerouslySetInnerHTML={{ __html: card.features }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Pricing;
