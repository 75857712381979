import { useLocation } from "@reach/router";
import React from "react";

const MachineTranslationWidgets = ({ data, selectedLanguage }) => {
  const location = useLocation();
  const currentLink = location.href;

  return (
    <div className="bg-[#f7f7f7]">
      <div className="max-w-7xl mx-auto p-4">
        <div>
          <div className="relative  pt-4 pb-14">
            <span
              className="text-4xl font-primary font-bold leading-[60px] text-center text-[#000000] 744px:block hidden"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />


            <iframe
              width="100%"
              height="600"
              src={`https://www.machinetranslation.com/embedded/translation?target=${selectedLanguage}&pageLink=${currentLink}`}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="744px:block hidden"
            ></iframe>

            <p
              className="font-opensans font-normal text-lg text-center leading-6 text-[#011C2A] 744px:block hidden"
              dangerouslySetInnerHTML={{ __html: data.bottom_text }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MachineTranslationWidgets;
