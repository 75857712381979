import React from "react";
import Layout from "components/layout";
import Block1 from "./bannerSingleLang";
import Block2 from "./secondFrench";
import SEO from "components/seo";
import Reviews from "components/Reviews";
import { reviewsData } from "components/Reviews/config";
import Block3 from "./thirdFrench";
import Pricing from "./Pricing";
import Block4 from "./contentDynamic";
import BottomLogoPanel from "./bottomLogoPanel";
import GetInTouch from "components/getInTouch";
import BreadCrumbComp from "../BreadCrumbComp/index";

import MachineTranslationWidgets from "./mtWidgets";

const LanguageDynamicPage = (props) => {
  const pageData = props.pageContext.data;

  const selectedLanguage = pageData.slug.split("/").pop();

  let BreadCrumb = pageData.meta.meta_title.split(" ").slice(0, 3).join(" ");

  const DataCrumb = [
    { name: "Home", item: "https://www.tomedes.com/" },
    {
      name: "Languages",
      item: "https://www.tomedes.com/language-translation-service.php",
    },
    { name: BreadCrumb },
  ];

  return (
    <Layout>
      <SEO
        title={pageData.meta.meta_title}
        description={pageData.meta.meta_description}
        keywords={pageData.meta.meta_keywords}
        slug={pageData.slug}
      />
      {pageData.widgets &&
        pageData.widgets.map(({ widget, data }, i) => {
          if (widget === "block_1") {
            return (
              <div key={i}>
                <Block1 data={data} />
                {pageData.meta.breadcrumbs && (
                  <BreadCrumbComp data={pageData.meta.breadcrumbs} />
                )}
              </div>
            );
          } else if (widget === "block_2") {
            return (
              <div key={i}>
                <Block2 data={data} />
              </div>
            );
          } else if (widget === "block_3") {
            return (
              <div key={i}>
                <Block3 data={data} />
              </div>
            );
          } else if (widget === "block_4") {
            return (
              <div key={i}>
                <Block4 data={data} />
              </div>
            );
          } else if (widget === "block_7") {
            return (
              <div key={i}>
                <Reviews data={data} />
              </div>
            );
          } else if (widget === "block_pricing") {
            return (
              <div key={i}>
                <Pricing data={data} />
              </div>
            );
          } else if (widget === "block_mt") {
            return (
              <div key={i}>
                <MachineTranslationWidgets
                  data={data}
                  selectedLanguage={selectedLanguage}
                />
              </div>
            );
          }
          return false;
        })}

      <BottomLogoPanel />
      <GetInTouch />
    </Layout>
  );
};
export default LanguageDynamicPage;
