import React from "react";
import ATA from "assets/images/new-homepage-assets/accreditation icons/ata.png";
import CLUTCH from "assets/images/new-homepage-assets/accreditation icons/clutch.png";
import USCIS from "assets/images/new-homepage-assets/accreditation icons/uscis.svg";
import { Link } from "gatsby";

const BottomLogoPanel = () => {
  return (
    <div className="bg-white py-16">
      <div className="w-9/12 flex sm:flex-row flex-col gap-y-6 justify-center gap-x-20 mx-auto items-center relative">
        <li className="logos flex flex-col items-center w-[175px]">
          <img src={ATA} alt="ata" className="lg:pb-12" />
          <div className="lg:absolute lg:bottom-0">
            <p className="text-[14px] text-lightBluegray pt-4 text-center">
              ATA Corporate
            </p>
            <p className="text-[14px] text-lightBluegray text-center ">
              Member Number 272444
            </p>
          </div>
        </li>

        <li className="logos flex flex-col items-center w-[175px] pt-6 lg:pt-0">
          <a
            href="https://clutch.co/translation/leaders-matrix"
            target="_blank"
          >
            <img
              src={CLUTCH}
              alt="clutch"
              className="flex justify-center mx-auto h-[120px] w-[112px]"
            />
            <div className="">
              <p className="text-[14px] text-lightBluegray pt-4 text-center">
                Clutch Top Global
              </p>
              <p className="text-[14px] text-lightBluegray text-center">
                Translation Services 2024{" "}
              </p>
            </div>
          </a>
        </li>
        <li className="logos flex flex-col items-center w-[175px] pt-6 lg:pt-0">
          <Link
            to="/uscis-translation-services"
            className="logos flex flex-col items-center w-[175px] pt-6 lg:pt-0"
          >
            <img src={USCIS} alt="uscis" className="lg:pb-12 pb-4" />
            <div className="lg:absolute lg:bottom-0">
              <p className="text-[14px] text-lightBluegray text-center">
                100% guaranteed
              </p>
              <p className="text-[14px] text-lightBluegray text-center">
                acceptance by USCIS{" "}
              </p>
            </div>
          </Link>
        </li>
      </div>
    </div>
  );
};
export default BottomLogoPanel;
