import React from "react";

const SecondFrench = (Data) => {
  return (
    <div className="bg-[#F2F9FC] py-16">
      <div className="flex flex-wrap sm:flex-nowrap gap-x-20 w-10/12 mx-auto justify-center">
        <div className="w-[530px]">
          <h2
            className="text-[40px] font-semibold xs:font-secondary text-midBlue"
            dangerouslySetInnerHTML={{
              __html: Data.data.title_block_2,
            }}
          />
        </div>
        <div className="w-[473px]">
          <div
            className="text-[18px] leading-loose font-sans text-midBlue"
            dangerouslySetInnerHTML={{
              __html: Data.data.description_block_2,
            }}
          />
          <p>{}</p>
        </div>
      </div>
    </div>
  );
};

export default SecondFrench;
