import React from "react";
import { Link } from "gatsby";
import ReviewSvg from "assets/images/review_stars.svg";
import HomepageReviewCards from "components/homepage/HomepageReviewCard";
import GlobalFixContactus from "components/reusableComponents/GlobalFixContactus";
import OptimizedImage from "hooks/OptimizedImage";

const ThirdFrench = (Data) => {
  return (
    <div className="pt-24 bg-gradient-to-b from-white to-gradient1">
      <div className="w-full mx-auto flex flex-col justify-center">
        <h3 className="text-[40px] xs:font-secondary font-semibold text-midBlue text-center">
          {Data.data.title_block_3}
        </h3>
        <div>
          <ul className="div w-full gap-x-[6.75rem] flex lg:flex-row flex-col justify-center items-center lg:relative pt-6 lg:pt-14">
            <li className="logos flex flex-col items-center w-[175px] pt-6 lg:pt-0">
              <OptimizedImage
                src="	https://www.tomedes.com/static/9001-da0972656b253aad9080ecef8f1922e1.webp"
                alt="ISO 9001:2015"
                className="lg:pb-12 w-[10rem] h-auto"
              />
              <div className=" lg:absolute lg:bottom-[-37px] w-[202px]">
                <p className="text-[18px] font-sans font-medium text-lightBluegray pt-4  text-center">
                  ISO 9001:2015
                </p>
                <p className="text-[18px] font-sans font-bold text-lightBluegray text-center">
                  {" "}
                  Quality Management Systems
                </p>
              </div>
            </li>
            <li className="logos flex flex-col items-center w-[175px] pt-6 lg:pt-0">
              <OptimizedImage
                src="https://www.tomedes.com/static/17100-0ff9c3017e6dba8263d7287b8d9e2c9b.webp"
                alt="ISO 18587:2017"
                className="lg:pb-12 w-[10rem] h-auto"
              />
              <div className=" lg:absolute lg:bottom-[-37px] w-[202px]">
                <p className="text-[18px] font-sans font-medium text-lightBluegray pt-4  text-center">
                  ISO 17100:2015{" "}
                </p>
                <p className="text-[18px] font-sans font-bold text-lightBluegray text-center">
                  Translation Services Quality
                </p>
              </div>
            </li>
            <li className="logos flex flex-col items-center w-[175px] pt-6 lg:pt-0 sm:pb-0 pb-4">
              <OptimizedImage
                src="	https://www.tomedes.com/static/18587-a1c764d0ddf5cec6cfbeef6f79744ba9.webp"
                alt="ISO 17100:2015"
                className="lg:pb-12 w-[10rem] h-auto"
              />
              <div className=" lg:absolute lg:bottom-[-37px] w-[202px]">
                <p className="text-[18px] font-sans font-medium text-lightBluegray pt-4  text-center">
                  ISO 18587:2017{" "}
                </p>
                <p className="text-[18px] font-sans font-bold text-lightBluegray text-center">
                  Post-editing Machine Translation Output
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="pb-16 mx-auto pt-36 sm:pt-48">
        <div className="w-full max-w-6xl mx-auto px-4">
          <div className="md:grid md:grid-cols-2 gap-8 justify-items-end">
            <div className="mb-8 md:mb-0">
              {/* <h3 className="xs:font-secondary text-40 font-bold">
                <span className="text-midBlue">See what our customers </span>
                <br />
                <span className="text-midBlue">
                  think about our translation services
                </span>
              </h3> */}
              <h3 className="xs:font-secondary text-[34px] md:text-40 text-midBlue font-bold">
                {Data.data.review_title
                  ? Data.data.review_title
                  : "See what our customers think about our translation services"}
              </h3>
              <img src={ReviewSvg} alt="review star" className="mt-5 mb-2" />
              <p className="font-opensans text-17 text-midBlue text-left mt-5">
                Tomedes takes pride in 95,000+ satisfied customers, around the
                globe, a number that includes several Fortune 500 companies. We
                have a 4.9 out of 5 aggregate star rating from
                <span className="font-bold">1,798 online reviews.</span>
              </p>
              <p className="mt-12 mb-12 md:mb-0">
                <Link
                  to="/reviews.php"
                  className="uppercase rounded-full bg-orange hover:bg-newOrange-100 text-white cursor-pointer text-17 font-opensans font-semibold py-4 px-8 w-[253px] text-center transition-colors duration-300"
                >
                  SEE ALL REVIEWS
                </Link>
              </p>
            </div>
            <HomepageReviewCards />
          </div>
        </div>
      </div>
      <GlobalFixContactus
        head={Data.data.translate_now_title}
        para=" "
        btnName="Translate Now"
        link="/quote"
      />
    </div>
  );
};
export default ThirdFrench;
