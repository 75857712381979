import React, { useEffect } from "react";

const ContentDynamic = (Data) => {
  useEffect(() => {
    anchorLayout();
    document.addEventListener("scroll", anchorScroll);
  }, []);

  const anchorScroll = () => {
    if (document.getElementsByClassName("layout-anchor").length > 0) {
      var navHeight = document.getElementsByClassName("sticky")[0].offsetHeight;
      var scrollPos =
        document.documentElement.scrollTop || document.body.scrollTop;
      var anchorLayoutPosition =
        document.querySelector(".layout-anchor").offsetTop;
      var ulList = document.querySelectorAll(".anchor-navigation ul > li > a");
      Array.prototype.forEach.call(ulList, function (el, i) {
        var index = el.getAttribute("index");
        var refElement = document.getElementById(index);
        var currLink = el;
        var elemPos = refElement.offsetTop - navHeight;
        if (currLink.classList.contains("customAnchor")) {
          if (scrollPos >= refElement.offsetTop) {
            Array.prototype.forEach.call(ulList, function (el2, i) {
              el2.classList.remove("active");
            });
            el.classList.add("active");
          }
        } else {
          if (scrollPos >= elemPos) {
            Array.prototype.forEach.call(ulList, function (el3, i) {
              el3.classList.remove("active");
            });
            el.classList.add("active");
          }
        }
      });
    }
  };

  const anchorLayout = () => {
    if (document.getElementsByClassName("layout-anchor").length > 0) {
      var navHeight = document.getElementsByClassName("sticky")[0].offsetHeight;

      var ulList = document.querySelectorAll(".anchor-navigation ul > li > a");
      Array.prototype.forEach.call(ulList, function (el, i) {
        el.addEventListener(
          "click",
          function () {
            // Array.prototype.forEach.call(ulList, function(el2, i){
            //   el2.classList.remove("active")
            // });
            var index = el.getAttribute("index");
            document.getElementById(index).scrollIntoView({
              behavior: "smooth",
            });
            // el.classList.add("active")
          },
          false
        );
      });
    }
  };

  return (
    <div className="parent-div-scroll bg-white bg-cover bg-no-repeat pt-12 flex-layout layout-anchor">
      <div className="w-full 2xl:w-10/12 mx-auto flex flex-wrap justify-around lg:justify-center">
        <div
          className="w-11/12 md:w-[450px] lg:w-8/12 pt-8 pb-16"
          id="anchorTagsColor relative"
        >
          {Data?.data?.content.map(({ title, description, heading, title_link }, index) => {
            return (
              <div
                className={heading == "H2" ? "pt-12" : ""}
                id={index}
                key={index}
              >
                {heading == "H2" ? (
                  <>
                    <h2 className="text-[40px] font-semibold xs:font-secondary text-midBlue pt-12 scroll-mt-20 md:w-full lg:w-[700px] 2xl:w-max-w-max">
                      {title}
                    </h2>

                    <div
                      className="text-[18px] font-sans text-midBlue list_disc_css leading-loose pt-[50px] w-full md:w-full lg:w-[700px]"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  </>
                ) : null}

                  {heading == "H3" ? (
                    <div>
                      {title_link ? (
                        <h3 className="text-[30px] font-semibold xs:font-secondary text-midBlue pt-12 md:w-full lg:w-[700px] 2xl:w-max-w-max">
                          <a
                            href={title_link}
                            target="_blank"
                            className="hover:text-newOrange-100 transition-colors duration-300"
                          >
                            {title}
                          </a>
                        </h3>
                      ) : (
                        <h3
                          className="text-[30px] font-semibold xs:font-secondary text-midBlue pt-12 md:w-full lg:w-[700px] 2xl:w-max-w-max"
                          dangerouslySetInnerHTML={{
                            __html: title,
                          }}
                        />
                      )}
                      <div
                        className="text-[18px] font-sans text-midBlue list_disc_css leading-loose pt-[50px] list_disc_css w-full md:w-full lg:w-[700px]"
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </div>
                  ) : null}

                {heading == "H3_half" ? (
                  <div
                    className={
                      title == "Business Documents"
                        ? " sm:absolute mb-[25rem] top-[199.7rem] left-[28rem]  2xl:top-[200rem] 2xl:left-[42rem] list_disc_css"
                        : "sm:mb-[23rem] mb-[15rem] list_disc_css"
                    }
                  >
                    <h4
                      className="text-[26px] font-semibold xs:font-secondary text-midBlue pt-12 "
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />

                    <div
                      className="text-[18px] list_disc_css font-sans text-midBlue leading-loose  sm:w-[50%] float-left pt-[50px]"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  </div>
                ) : null}

                {heading == "H4" ? (
                  <>
                    <h4
                      className="text-[26px] font-semibold xs:font-secondary  text-midBlue pt-12 md:w-full lg:w-[700px] 2xl:w-max-w-max"
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />

                    <div
                      className="text-[18px] font-sans list_disc_css text-midBlue leading-loose pt-[50px] w-full md:w-full lg:w-[700px]"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  </>
                ) : null}
              </div>
            );
          })}
        </div>
        <div className="md:w-[280px] lg:w-3/12 hidden md:block anchor-navigation">
          <div id="scrollableElementId" class="fixedElement">
            <ul>
              {Data?.data?.content.map(({ title, heading }, index) => {
                return (
                  <>
                    {heading === "H2" ? (
                      <li key={index}>
                        <a
                          index={index}
                          class={
                            index === 0
                              ? "customAnchor active scrollTo" + index
                              : "scrollTo" + index
                          }
                        >
                          <p className="text-[#5C636D] leading-6 text-[15px] font-sans ">
                            {title}
                          </p>
                        </a>
                      </li>
                    ) : null}
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-midBlue h-[325px] flex flex-col justify-center items-center">
        <h2 className="text-[55px] font-semibold xs:font-secondary text-white sm:text-left text-center">
          Ready to get started?
        </h2>
        <a
          href="/quote"
          style="top: 6788px;left: 502px;width: 277px;height: 60px;background: #FF7B16 0% 0% no-repeat padding-box;border-radius: 30px;opacity: 1;"
          className="text-[18px] font-sans font-semibold text-white flex justify-center items-center mt-8"
        >
          GET A FREE QUOTE NOW
        </a>
      </div>
    </div>
  );
};
export default ContentDynamic;
